<template>
  <ion-page>
    <!-- <div class="background-main-view main-view"> -->
    <ion-header class="ion-no-border">
      <main-view-header />
    </ion-header>
    <ion-content
      v-if="selectedView === '1'"
      class="ion-padding-bottom"
      :scroll-events="true"
    >
      <ion-row
        class="text-left ion-no-padding"
      >
        <ion-button
          v-if="selectedView === '1'"
          fill="clear"
          color="transparent"
          size="small"
          style="max-width: 80px;"
          @click="$router.push('/main-view')"
        >
          <img
            src="../assets/images/arrow-go-back-line.svg"
          >
        </ion-button>
        <span class="text-64 text--white">Overview <span class="text--primary">Statistics</span></span>
      </ion-row>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <div
              class="ion-padding chart-col-bg"
              style="transition: 0.4s;"
            >
              <div
                class="text-left"
                tappable
                @click="needsFollowUpExpanded = !needsFollowUpExpanded"
              >
                <div
                  class="text--white text-14 text--bold text--uppercase"
                  style="display: inline-block"
                >
                  <i
                    class="mdi mdi-alert text--primary"
                    style="font-size: 20px;"
                  />
                  Apartments needs follow up <span class="text--primary">( {{ overviewDetails.landlordDevicesInactivePropertyIds ? overviewDetails.landlordDevicesInactivePropertyIds.length : 0 }} )</span>
                </div>
                <div
                  style="display: inline-block;"
                  class="ion-float-end"
                >
                  <i
                    class="mdi text--white"
                    style="font-size: 20px;"
                    :class="needsFollowUpExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  />
                </div>
              </div>
              <div
                :style="needsFollowUpExpanded ? 'display: block;' : 'display: none; overflow: hidden;'"
              >
                <ion-list>
                  <ion-item
                    v-for="apartment in overviewDetails.landlordDevicesInactivePropertyIds"
                    :key="apartment"
                    detail="false"
                    lines="none"
                    class="apartment-item rounded-corners mt-8 text--white"
                    tappable
                    @click="$router.push(`/detail/${apartment}`)"
                  >
                    <!-- <ion-label>{{ apartmentName(apartment).propertyName }}</ion-label> -->
                    <ion-icon
                      :icon="buildingIco"
                      style="font-size: 25px; margin-right: 10px;"
                      class="text--primary"
                    />
                    <div style="width: 180px; display: inline;">
                      {{ mapBuilding(apartmentName(apartment).buildingId).buildingName }}
                    </div>
                    <i
                      class="mdi mdi-view-quilt text--primary"
                      style="font-size: 30px; margin-left: 10px; margin-right: 10px;"
                    />
                    <span>{{ apartmentName(apartment).propertyName }}</span>
                    <span
                      class="text--primary"
                      style="margin-left: 30px;"
                    >
                      <i
                        class="mdi mdi-alert"
                        style="font-size: 20px;"
                      />
                      {{ findDisconnectedDevices(apartment).length }} Device(s) Disconnected
                    </span>
                    <i
                      slot="end"
                      class="mdi mdi-chevron-right text--gray"
                      style="font-size: 25px;"
                    />
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </ion-col>
          <ion-col size="12">
            <div class="ion-padding chart-col-bg">
              <div
                class="text-left"
                tappable
                @click="offlineExpanded = !offlineExpanded"
              >
                <div
                  class="text--white text-14 text--bold text--uppercase"
                  style="display: inline-block"
                >
                  <i
                    class="mdi mdi-alert text--primary"
                    style="font-size: 20px;"
                  />
                  Offline apartments <span class="text--primary">( {{ offlineApartments.length }} )</span>
                </div>
                <div
                  style="display: inline-block;"
                  class="ion-float-end"
                >
                  <i
                    class="mdi text--white"
                    style="font-size: 20px;"
                    :class="offlineExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  />
                </div>
              </div>
              <div
                :style="offlineExpanded ? 'display: block;' : 'display: none; overflow: hidden;'"
              >
                <ion-list class="ion-no-margin">
                  <ion-item
                    v-for="apartment in offlineApartments"
                    :key="apartment"
                    detail="false"
                    lines="none"
                    tappable
                    class="apartment-item rounded-corners mt-8 text--white"
                    @click="$router.push(`/detail/${apartment.id}`)"
                  >
                    <ion-icon
                      :icon="buildingIco"
                      style="font-size: 25px; margin-right: 10px;"
                      class="text--primary"
                    />
                    <div style="width: 180px; display: inline;">
                      {{ mapBuilding(apartment.buildingId).buildingName }}
                    </div>
                    <i
                      class="mdi mdi-view-quilt text--primary"
                      style="font-size: 30px; margin-left: 10px; margin-right: 10px;"
                    />
                    <span>{{ apartment.propertyName }}</span>
                    <span
                      class="text--primary"
                      style="margin-left: 30px;"
                    >
                      <i
                        class="mdi mdi-alert"
                        style="font-size: 20px;"
                      />
                      Gateway Disconnected <span class="text--white">since {{ new Date(Number(apartment.disconnectedAt)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }} {{ new Date(Number(apartment.disconnectedAt)).toLocaleDateString('sv-SV') }}</span>
                    </span>
                    <i
                      slot="end"
                      class="mdi mdi-chevron-right text--gray"
                      style="font-size: 25px;"
                    />
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </ion-col>
          <ion-col size="12">
            <div class="ion-padding chart-col-bg">
              <div
                class="text-left"
                tappable
                @click="notInstalledExpanded = !notInstalledExpanded"
              >
                <div
                  class="text--white text-14 text--bold text--uppercase"
                  style="display: inline-block"
                >
                  <i
                    class="mdi mdi-alert-circle text--primary"
                    style="font-size: 20px;"
                  />
                  Not installed apartments <span class="text--primary">( {{ overviewDetails.landlordPropertiesLength - overviewDetails.landlordPropertiesGatewayInstalled }} )</span>
                </div>
                <div
                  style="display: inline-block;"
                  class="ion-float-end"
                >
                  <i
                    class="mdi text--white"
                    style="font-size: 20px;"
                    :class="notInstalledExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  />
                </div>
              </div>
              <div
                :style="notInstalledExpanded ? 'display: block;' : 'display: none; overflow: hidden;'"
              >
                <ion-list class="ion-no-margin">
                  <ion-item
                    v-for="apartment in notInstalledApartments"
                    :key="apartment"
                    detail="false"
                    lines="none"
                    tappable
                    class="apartment-item rounded-corners mt-8 text--white"
                    @click="$router.push(`/detail/${apartment.id}`)"
                  >
                    <ion-icon
                      :icon="buildingIco"
                      style="font-size: 25px; margin-right: 10px;"
                      class="text--primary"
                    />
                    <div style="width: 180px; display: inline;">
                      {{ mapBuilding(apartment.buildingId).buildingName }}
                    </div>
                    <i
                      class="mdi mdi-view-quilt text--primary"
                      style="font-size: 30px; margin-left: 10px; margin-right: 10px;"
                    />
                    <span>{{ apartment.propertyName }}</span>
                    <span
                      class="text--primary"
                      style="margin-left: 30px;"
                    >Gateway Not installed</span>
                    <i
                      slot="end"
                      class="mdi mdi-chevron-right text--gray"
                      style="font-size: 25px;"
                    />
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <div style="height: 80px;" />
      </ion-grid>
    </ion-content>
    <!-- </div> -->
  </ion-page>
</template>

<script>
import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'
import buildingIco from '../assets/images/building-ico.svg'

export default {
  components: {
    mainViewHeader
  },
  data () {
    return {
      buildingIco,
      loading: false,
      selectedView: '1',
      needsFollowUpExpanded: false,
      offlineExpanded: false,
      notInstalledExpanded: false
    }
  },
  computed: {
    landlordDevices () {
      return this.$store.state.landlordDevices
    },
    overviewDetails () {
      console.log(this.$store.state.landlordOverviewPieData)
      return this.$store.state.landlordOverviewPieData
    },
    offlineApartments () {
      const offline = this.$store.state.landlordProperties.filter(item => item.gatewayActive === false && item.propertyGateway)
      return offline
    },
    notInstalledApartments () {
      return this.$store.state.landlordProperties.filter(item => item.propertyGateway === null)
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    apartmentName (apartment) {
      return this.$store.state.landlordProperties.filter(item => item.id === apartment)[0]
    },
    mapBuilding (id) {
      return this.$store.state.landlordBuildings.filter(item => item.id === id)[0]
    },
    findDisconnectedDevices (id) {
      return this.landlordDevices.filter(item => item.propertyId === id && item.active === false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.text-64 {
    font-size: 36px;
}
.chart-col-bg {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.apartment-item {
    @include bg-box-item;
   --min-height: 60px;
}
</style>